import { ItemList } from '../../../assets/scripts/components/item-list'
import { apiCall, DELETE, GET, POST, redirectToLoginAndReturn, RequestStatusError } from '../../../assets/scripts/interfaces/api-call'
import Component from '../../../assets/scripts/modules/component'
import { buildIdea } from './components/idea-section-idea'

/**
 * Fetch ideas from backend
 *
 * @param templateLibrary
 * @param pageUuid
 * @param offset
 * @param limit
 * @returns {Promise<{total, ideas}|{total: *, ideas: *[]}>}
 */
async function fetchIdeas (templateLibrary, api, pageUuid, offset, limit) {
  // For testing purposes
  if (!pageUuid) {
    return {
      total: limit + 1, // this should show the load more button once
      items: [],
      isTest: true
    }
  }

  // regular behaviour
  const params = new URLSearchParams({ offset, limit }).toString()
  const endpoint = `/rest-api/v1/community/pages/${pageUuid}/feed/`
  const data = await apiCall(GET, `${endpoint}?${params}`)
  return {
    total: data.count,
    items: data.results.map(serializeTwistIdeaData).map(item => buildIdea(templateLibrary, api, item))
  }
}

async function likeIdea (ideaUuid) {
  // For testing purposes
  if (!ideaUuid) {
    return {
      likeCount: 1
    }
  }

  // Regular behaviour
  const endpoint = `/rest-api/v1/community/posts/${ideaUuid}/like/`
  let data

  try {
    data = await apiCall(POST, endpoint, {}, window.CNV_APP.csrfToken)
  } catch (err) {
    if (err instanceof RequestStatusError) {
      if (err.status === 403) {
        redirectToLoginAndReturn()
        return
      }
      throw err
    }
  }

  // Datalayer
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'cao_like_idea',
    cao_category: sessionStorage.getItem('caoCategory')
  })

  return {
    likeCount: data.likes
  }
}

async function unlikeIdea (ideaUuid) {
  // For testing purposes
  if (!ideaUuid) {
    return {
      likeCount: 0
    }
  }

  // Regular behaviour
  const endpoint = `/rest-api/v1/community/posts/${ideaUuid}/like/`
  let data

  try {
    data = await apiCall(DELETE, endpoint, {}, window.CNV_APP.csrfToken)
  } catch (err) {
    if (err instanceof RequestStatusError) {
      if (err.status === 403) {
        redirectToLoginAndReturn()
        return
      }
      throw err
    }
  }

  return {
    likeCount: data.likes
  }
}

/**
 * Report an idea
 *
 * @param endpoints
 * @param ideaUuid
 * @returns {Promise<boolean>}
 */
async function reportIdea (endpoints, ideaUuid) {
  // For testing purposes
  if (!ideaUuid) {
    return true
  }

  const endpoint = '/rest-api/v1/report_entity/post/'
  const response = await apiCall(POST, endpoint, { post_uuid: ideaUuid }, window.CNV_APP.csrfToken, false)

  return response.status === 200
}

/**
 * Remove an idea
 *
 * @param ideaUuid
 * @returns {Promise<boolean>}
 */
async function removeIdea (ideaUuid) {
  // For testing purposes
  if (!ideaUuid) {
    return true
  }

  const endpoint = `/rest-api/v1/community/posts/${ideaUuid}/`

  let response

  try {
    response = await apiCall(DELETE, endpoint, {}, window.CNV_APP.csrfToken, false)
  } catch (err) {
    if (err instanceof RequestStatusError) {
      if (err.status === 403) {
        redirectToLoginAndReturn()
      }

      return false
    }
  }

  return response.status === 200
}

/**
 * Convert twist it idea data to regular idea data
 *
 * @param ideaData
 * @returns {{likedByUser: *, changeEndpoint: string, removeEndpoint: string, reportEndpoint: string, likeCount: *, text, avatar: *, title, uuid, username: *, labels: *[]}}
 */
function serializeTwistIdeaData (ideaData) {
  const labels = []

  if (ideaData.is_owned) {
    labels.push('Jij')
  }

  if (ideaData.created_by?.is_cnv_expert) {
    labels.push('CNV Expert')
  }

  if (ideaData.created_by?.is_moderator) {
    labels.push('Moderator')
  }

  return {
    uuid: ideaData.uuid,
    title: ideaData.content.title,
    text: ideaData.content.text,
    avatar: ideaData.created_by?.profile_photo?.url,
    username: ideaData.created_by?.name,
    userSlug: ideaData.created_by?.slug,
    labels,
    likeCount: ideaData.likes,
    likedByUser: ideaData.is_liked,
    isOwned: ideaData.is_owned
  }
}

class IdeaSection extends Component {
  init () {
    const templateDropdownMenu = this.element.querySelector('.idea-section__template-dropdown-menu')
    const templateDropdownMenuItem = this.element.querySelector('.idea-section__template-dropdown-menu-item')
    const templateIconReport = this.element.querySelector('.idea-section__template-icon-report')
    const templateIconRemove = this.element.querySelector('.idea-section__template-icon-remove')
    const templateIdea = this.element.querySelector('.idea-section__template-idea')
    const templateList = this.element.querySelector('.idea-section__template-list')
    const templateLoadMoreButton = this.element.querySelector('.idea-section__template-load-more-button')
    const templateUserLabel = this.element.querySelector('.idea-section__template-user-label')

    this.body = this.element.querySelector('.idea-section-body')
    this.pageUuid = this.element.dataset.uuid

    // template library
    this.library = {
      iconReport: templateIconReport,
      iconRemove: templateIconRemove,
      idea: templateIdea,
      dropdownMenu: templateDropdownMenu,
      dropdownMenuItem: templateDropdownMenuItem,
      list: templateList,
      loadMoreButton: templateLoadMoreButton,
      userLabel: templateUserLabel
    }

    this.api = {
      fetchIdeas: (offset, limit) => fetchIdeas(this.library, this.api, this.pageUuid, offset, limit),
      likeIdea,
      unlikeIdea,
      reportIdea,
      removeIdea
    }

    // Build the main idea list
    this.mainIdeaList = ItemList(this.library.list, this.library.loadMoreButton, this.api.fetchIdeas)
    this.body.appendChild(this.mainIdeaList.element)

    // Listen to modal dialog completions and act on them
    window.addEventListener('modal-form-completed', (event) => {
      const { modal } = event.detail
      if (modal.id === this.element.dataset.addIdeaModalId) {
        // Datalayer
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'cao_submit_idea',
          cao_category: sessionStorage.getItem('caoCategory')
        })
        this.mainIdeaList.reload()
      }
    })
  }

  addIdea (ideaData) {
    const newIdea = buildIdea(this.library, this.api, ideaData)
    this.mainIdeaList.addItem(newIdea)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.idea-section').forEach(element => {
  element.instance = element.instance || new IdeaSection(element)
}))
