import Component from '../../../assets/scripts/modules/component'

export default class AccordionComponent extends Component {
  init () {
    this.accordionButtons = this.element.querySelectorAll('.accordion-item')

    for (const button of this.accordionButtons) {
      button.addEventListener('click', () => {
        this.toggleAccordion(button)
      })
    }
  }

  toggleAccordion (button) {
    button.classList.toggle('open')

    const title = button.querySelector('.accordion-item__title')
    const target = title.dataset.target
    const panel = button.querySelector(`#${target}`)
    if (panel) {
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null
      } else {
        panel.style.maxHeight = `${panel.scrollHeight}px`
      }
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.accordion').forEach(element => {
  element.instance = element.instance || new AccordionComponent(element)
}))
